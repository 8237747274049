// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// Components
import ScaleDown from '../Animation/ScaleDown'
import Reveal from '../Animation/Reveal'
import ProjectsList from './ProjectsList'

// Styles
import { HomeSection, HomeTitle } from '../common/Section.styled'
import { CtaLink as Cta } from '../common/Section.styled'

const Work = ({ projects }) => {
  const featured =
    projects &&
    projects
      .filter(item => !!item.featured)
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(0, 3)

  return (
    <HomeSection>
      <ScaleDown>
        <HomeTitle>work</HomeTitle>
      </ScaleDown>
      <ProjectsList
        projects={featured}
        title="Featured Projects"
        shouldAllowExtra={false}
      />
      <Reveal fluid>
        <Cta
          to="/work"
          href
          onClick={() => {
            trackCustomEvent({
              category: 'Home Button',
              action: 'Click',
              label: 'See all projects',
            })
          }}
        >
          See all projects
        </Cta>
      </Reveal>
    </HomeSection>
  )
}

// Components PropTypes
Work.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      logo: PropTypes.shape({
        fluid: PropTypes.shape({
          src: PropTypes.string,
          srcSet: PropTypes.string,
          srcWebp: PropTypes.string,
          srcSetWebp: PropTypes.string,
          sizes: PropTypes.string,
          aspectRatio: PropTypes.number,
          base64: PropTypes.string,
        }),
      }),
    })
  ),
}
Work.defaultProps = {
  projects: [],
}

export default Work
