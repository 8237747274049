// Dependencies
import styled from 'styled-components'

// Constants
import colors from '../../constants/colors'
import { sourceCodePro } from '../../constants/fonts'
import { maxWidth } from '../../constants/general'
import { breakpoints, sizesMin, sizesMax } from '../../constants/breakpoints'

export const HomeHeader = styled.div`
  ${maxWidth};
  padding: 50px 30px;

  @media (max-width: ${sizesMax.tablet}) {
    padding: 30px 20px;
  }

  @media (max-width: ${sizesMax.mobileL}) {
    padding: 5vw;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  @media (max-width: ${sizesMax.laptop}) and (min-width: ${sizesMin.tablet}) {
    max-height: 70vh;
    justify-content: space-evenly;
  }
  @media (min-width: ${sizesMin.laptop}) {
    max-height: 100vh;
    min-height: 100vh;
    justify-content: space-evenly;
  }
`

export const Logo = styled.div`
  max-width: 480px;
  height: auto;
  width: 150px;
  position: relative;
  display: block;

  @media ${breakpoints.mobileM} {
    width: 150px;
  }
  @media ${breakpoints.mobileL} {
    width: 300px;
  }
  @media ${breakpoints.tablet} {
    width: 350px;
  }
  @media ${breakpoints.laptop} {
    width: 100%;
  }

  svg {
    width: 100%;
    height: 100%;

    .logo_name path {
      fill: ${colors.violet};
    }
    .logo_title path {
      fill: ${colors.violet};
    }
  }
`

export const Subtitle = styled.div`
  position: relative;
  width: 100%;
  margin-top: 50px;

  @media ${breakpoints.mobileM} {
    width: 100%;
  }
  @media ${breakpoints.mobileL} {
    width: 80%;
  }
  @media ${breakpoints.laptop} {
    width: 60%;
  }

  p {
    position: relative;
    line-height: 1.4;
    z-index: 6;
    color: ${colors.white};
    ${sourceCodePro};
    font-size: 14px;

    @media ${breakpoints.mobileM} {
      font-size: 14px;
    }
    @media ${breakpoints.mobileL} {
      font-size: 18px;
    }
    @media ${breakpoints.tablet} {
      font-size: 20px;
    }
    @media ${breakpoints.laptop} {
      font-size: 24px;
    }
  }
`

export const SocialIcons = styled.div`
  position: relative;
  display: table;
  margin-top: 30px;

  @media ${breakpoints.tablet} {
    margin-top: 40px;
  }
  @media ${breakpoints.laptop} {
    margin-top: 50px;
  }

  a {
    display: inline-block;
    position: relative;
    height: 40px;
    width: 40px;
    margin-right: 5px;
    background: ${colors.lighterViolet};
    transition: all 0.2s ease-in;

    svg {
      width: 100%;
      height: 100%;
      position: relative;
      display: block;
      path,
      polygon,
      rect {
        fill: ${colors.black};
        transition: all 0.2s ease-in;
      }
    }

    &:focus,
    &:hover {
      background: ${colors.violet};
      svg {
        width: 100%;
        height: 100%;
        position: relative;
        display: block;
        path,
        polygon,
        rect {
          fill: ${colors.white};
          transition: all 0.2s ease-in;
        }
      }
    }

    @media ${breakpoints.tablet} {
      height: 40px;
      width: 40px;
      margin-right: 10px;
    }
    @media ${breakpoints.laptop} {
      height: 50px;
      width: 50px;
      margin-right: 15px;
    }

    :last-child {
      margin-right: 0;
    }
  }
`
