// Dependencies
import React from 'react'
import PropTypes from 'prop-types'

// Components
import ScaleDown from '../Animation/ScaleDown'
import FadeIn from '../Animation/FadeIn'
import ProfileBackground from './ProfileBackground'

// Styles
import { HomeSection, HomeTitle } from '../common/Section.styled'
import { AboutContent, ProfilePicture, Squares, Image } from './About.styled'
import { ProfileIntro, ProfileImage } from './About.styled'

const About = ({ introTitle, introText, profilePicture }) => {
  return (
    <HomeSection>
      <ScaleDown>
        <HomeTitle>about</HomeTitle>
      </ScaleDown>
      <AboutContent>
        <ProfileIntro>
          <ProfileBackground title={introTitle} text={introText} showCta />
        </ProfileIntro>
        <ProfilePicture>
          <FadeIn>
            <ProfileImage fluid={profilePicture.fluid} />
            <Squares />
          </FadeIn>
        </ProfilePicture>
      </AboutContent>
    </HomeSection>
  )
}

// Components PropTypes
About.propTypes = {
  introTitle: PropTypes.string.isRequired,
  introText: PropTypes.string.isRequired,
  profilePicture: PropTypes.shape({
    fluid: PropTypes.shape({
      src: PropTypes.string,
      srcSet: PropTypes.string,
      srcWebp: PropTypes.string,
      srcSetWebp: PropTypes.string,
      sizes: PropTypes.string,
      aspectRatio: PropTypes.number,
      base64: PropTypes.string,
    }),
  }).isRequired,
}

export default About
