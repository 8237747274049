// Dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

// Components
import { twitterSVG, linkedinSVG, emailSVG, phoneSVG } from '../../assets/icons'
import Reveal from '../Animation/Reveal'

// Styles
import { HomeHeader, Logo, Subtitle, SocialIcons } from './Header.styled'

const Header = ({ headline, logoSvg, twitter, linkedin, email, telephone }) => (
  <header>
    <HomeHeader>
      <Logo>
        <Reveal>
          <div dangerouslySetInnerHTML={{ __html: logoSvg }} />
        </Reveal>
      </Logo>
      <div>
        <Subtitle>
          <Reveal>
            <p>{headline}</p>
          </Reveal>
        </Subtitle>

        <SocialIcons>
          <Reveal>
            {twitter && (
              <a
                href={twitter}
                target="_blank"
                rel="noopener noreferrer"
                dangerouslySetInnerHTML={{ __html: twitterSVG }}
                onClick={() => {
                  trackCustomEvent({
                    category: 'Header',
                    action: 'Click',
                    label: 'Twitter Link',
                  })
                }}
              />
            )}
            {linkedin && (
              <a
                href={linkedin}
                target="_blank"
                rel="noopener noreferrer"
                dangerouslySetInnerHTML={{ __html: linkedinSVG }}
                onClick={() => {
                  trackCustomEvent({
                    category: 'Header',
                    action: 'Click',
                    label: 'Linkedin Link',
                  })
                }}
              />
            )}
            {email && (
              <a
                href={'mailto:' + email}
                target="_blank"
                rel="noopener noreferrer"
                dangerouslySetInnerHTML={{ __html: emailSVG }}
                onClick={() => {
                  trackCustomEvent({
                    category: 'Header',
                    action: 'Click',
                    label: 'Email Link',
                  })
                }}
              />
            )}
            {telephone && (
              <a
                href={'tel:' + telephone}
                target="_blank"
                rel="noopener noreferrer"
                dangerouslySetInnerHTML={{ __html: phoneSVG }}
                onClick={() => {
                  trackCustomEvent({
                    category: 'Header',
                    action: 'Click',
                    label: 'Phone Link',
                  })
                }}
              />
            )}
          </Reveal>
        </SocialIcons>
      </div>
    </HomeHeader>
  </header>
)

// Components PropTypes
Header.propTypes = {
  headline: PropTypes.string,
  logoSvg: PropTypes.string,
}
Header.defaultProps = {
  headline: '',
  logoSvg: '',
}

export default Header
