import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from '../components/Helmet'

// Components
import Header from '../components/Header/Header'
import About from '../components/About/About'
import Work from '../components/Work/Work'
import Layout from '../components/layout'

const RootIndex = props => {
  const siteInfo = get(props, 'data.allContentfulGeneral.nodes[0]')
  const introTitle = get(props, 'data.allContentfulAbout.nodes[0].introTitle')
  const introText = get(
    props,
    'data.allContentfulAbout.nodes[0].introText.childMarkdownRemark.rawMarkdownBody'
  )
  const profilePicture = get(
    props,
    'data.allContentfulAbout.nodes[0].profilePicture'
  )
  const projects = get(props, 'data.allContentfulProject.nodes')

  return (
    <Layout>
      <div>
        <Helmet {...siteInfo} />
        <div>
          <div>
            <Header
              headline={siteInfo.homeHeaderParagraph}
              logoSvg={siteInfo.logoSvg.logoSvg}
              {...{
                twitter: siteInfo.twitter,
                telephone: siteInfo.telephone,
                linkedin: siteInfo.linkedin,
                email: siteInfo.email,
              }}
            />
            <About {...{ introTitle, introText, profilePicture }} />
            <Work projects={projects} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulGeneral {
      nodes {
        ...general
      }
    }

    allContentfulAbout {
      nodes {
        ...about
      }
    }

    allContentfulProject {
      nodes {
        ...project
      }
    }
  }
`
